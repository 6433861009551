import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import {
  BarChart, Bar, PieChart, Pie, Cell,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const VisualizationDashboard = ({ results, formData }) => {
  if (!results) return null;

  // Make sure we have all the values we need from results
  const mttr = results.mttr;
  const mttrNew = results.mttrNew;
  const improvementHours = (mttr - mttrNew).toFixed(1);
  const improvementPercentage = Math.round((mttr - mttrNew) / mttr * 100);
  
  // Data for MTTR comparison - using our guaranteed values
  const mttrData = [
    { name: 'Current', value: mttr, color: '#ef4444' },
    { name: 'With TicketSidekick', value: mttrNew, color: '#3b82f6' }
  ];

  // Get data from results
  const incidents = formData.incidents;
  const timePerIncident = formData.timePerIncident;
  const falsePositiveRate = formData.falsePositives / 100;
  
  // Calculate weekly and annual hours
  const weeklyMinutes = incidents * timePerIncident;
  const weeklyHours = weeklyMinutes / 60;
  const annualHours = weeklyHours * 52;
  
  // Calculate hours for different categories based on results
  const falsePositiveHours = results.falsePositiveHours; // Current false positive hours
  const automatedHours = results.hoursSaved; // Hours saved through automation
  
  // For the "without" chart - current state
  const actualIncidentHoursWithout = annualHours - falsePositiveHours;
  
  // For the "with" chart - TicketSidekick reduces false positives by 70%
  const falsePositiveHoursReduced = falsePositiveHours * 0.3; // Reduce false positives by 70%
  const actualIncidentHoursWith = actualIncidentHoursWithout * 0.6; // Reduce actual incident time by 40%
  
  // Create data for the two charts
  const timeAllocationWithout = [
    { name: 'False Positives', value: falsePositiveHours, color: '#f59e0b' },
    { name: 'Actual Incidents', value: actualIncidentHoursWithout, color: '#3b82f6' }
  ];
  
  const timeAllocationWith = [
    { name: 'False Positives', value: falsePositiveHoursReduced, color: '#f59e0b' },
    { name: 'Actual Incidents', value: actualIncidentHoursWith, color: '#3b82f6' },
    { name: 'Automated', value: automatedHours, color: '#10b981' }
  ];

  return (
    <Box sx={{ color: 'white' }}>
      <Box sx={{ display: 'flex', gap: 4, mb: 4, flexWrap: 'wrap' }}>

        {/* Title for the time allocation comparison */}
        <Grid container spacing={4} sx={{ width: '100%' }}>          
          {/* Without TicketSidekick */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ bgcolor: '#1e293b', p: 3, borderRadius: 2, height: '100%' }}>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>Today</Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={timeAllocationWithout}
                      cx="50%"
                      cy="50%"
                      innerRadius={70}
                      outerRadius={100}
                      paddingAngle={2}
                      dataKey="value"
                      label={({ name, value }) => `${value} hrs`}
                      labelLine={true}
                    >
                      {timeAllocationWithout.map((entry, index) => (
                        <Cell key={`cell-without-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Legend 
                      verticalAlign="bottom"
                      wrapperStyle={{ paddingTop: 20 }}
                      formatter={(value, entry, index) => (
                        <span style={{ color: '#f8fafc' }}>{value}</span>
                      )}
                    />
                    <Tooltip 
                      contentStyle={{ 
                        backgroundColor: '#0f172a', 
                        borderColor: '#334155', 
                        color: '#ffffff'
                      }}
                      formatter={(value, name) => {
                        return [
                          <span style={{ color: '#ffffff' }}>{`${Math.round(value).toLocaleString()} hours`}</span>, 
                          <span style={{ color: '#ffffff' }}>{name}</span>
                        ];
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>
          
          {/* With TicketSidekick */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ bgcolor: '#1e293b', p: 3, borderRadius: 2, height: '100%' }}>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>With TicketSidekick</Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={timeAllocationWith}
                      cx="50%"
                      cy="50%"
                      innerRadius={70}
                      outerRadius={100}
                      paddingAngle={2}
                      dataKey="value"
                      label={({ name, value }) => `${value} hrs`}
                      labelLine={true}
                    >
                      {timeAllocationWith.map((entry, index) => (
                        <Cell key={`cell-with-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Legend 
                      verticalAlign="bottom"
                      wrapperStyle={{ paddingTop: 20 }}
                      formatter={(value, entry, index) => (
                        <span style={{ color: '#f8fafc' }}>{value}</span>
                      )}
                    />
                    <Tooltip 
                      contentStyle={{ 
                        backgroundColor: '#0f172a', 
                        borderColor: '#334155', 
                        color: '#ffffff'
                      }}
                      formatter={(value, name) => {
                        return [
                          <span style={{ color: '#ffffff' }}>{`${Math.round(value).toLocaleString()} hours`}</span>, 
                          <span style={{ color: '#ffffff' }}>{name}</span>
                        ];
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default VisualizationDashboard;