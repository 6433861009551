import React from 'react';
import { 
  LineChart, 
  Line, 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  ReferenceLine
} from 'recharts';
import { Box, Typography, Paper } from '@mui/material';

const DashboardPreview = () => {
  // Sample ticket data for charts
  const ticketTrends = [
    { name: 'Mon', newTickets: 65, resolvedTickets: 55, avgResponseTime: 3.2 },
    { name: 'Tue', newTickets: 59, resolvedTickets: 63, avgResponseTime: 2.8 },
    { name: 'Wed', newTickets: 80, resolvedTickets: 70, avgResponseTime: 3.5 },
    { name: 'Thu', newTickets: 81, resolvedTickets: 75, avgResponseTime: 2.9 },
    { name: 'Fri', newTickets: 56, resolvedTickets: 68, avgResponseTime: 2.4 },
    { name: 'Sat', newTickets: 30, resolvedTickets: 32, avgResponseTime: 1.8 },
    { name: 'Sun', newTickets: 25, resolvedTickets: 20, avgResponseTime: 1.5 },
  ];

  const ticketCategories = [
    { name: 'Login Issues', value: 35 },
    { name: 'Billing', value: 25 },
    { name: 'Feature Request', value: 20 },
    { name: 'Technical', value: 15 },
    { name: 'Other', value: 5 },
  ];

  const performanceData = [
    { name: 'Team A', score: 87 },
    { name: 'Team B', score: 75 },
    { name: 'Team C', score: 92 },
    { name: 'Team D', score: 64 },
  ];

  // Custom colors
  const lineColors = ['#4d7bfc', '#b7fa37'];
  const pieColors = ['#4d7bfc', '#6c8bfc', '#8e9efa', '#b0b1f9', '#d2d4f8'];
  const barColors = ['#4d7bfc'];

  return (
    <Box sx={{ 
      width: '100%', 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      p: 2
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 2 
      }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
            Ticket Analytics Dashboard
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Last 7 days overview
          </Typography>
        </Box>
        <Paper 
          sx={{ 
            bgcolor: 'rgba(31, 41, 55, 0.6)', 
            borderRadius: 2, 
            px: 1.5, 
            py: 0.5 
          }}
        >
          <Typography variant="body2" sx={{ color: 'white', fontWeight: 'medium' }}>
            🟢 87 Agents Online
          </Typography>
        </Paper>
      </Box>

      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(3, 1fr)', 
        gap: 2, 
        mb: 2 
      }}>
        <Paper sx={{ 
          bgcolor: 'rgba(31, 41, 55, 0.6)', 
          borderRadius: 2, 
          p: 1.5, 
          boxShadow: 2 
        }}>
          <Typography variant="caption" sx={{ color: 'text.secondary', mb: 0.5, display: 'block' }}>
            Total Tickets
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white' }}>
            396
          </Typography>
          <Typography variant="caption" sx={{ color: '#4caf50', mt: 1, display: 'block' }}>
            ↑ 12% from last week
          </Typography>
        </Paper>
        <Paper sx={{ 
          bgcolor: 'rgba(31, 41, 55, 0.6)', 
          borderRadius: 2, 
          p: 1.5, 
          boxShadow: 2 
        }}>
          <Typography variant="caption" sx={{ color: 'text.secondary', mb: 0.5, display: 'block' }}>
            Resolution Rate
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white' }}>
            89%
          </Typography>
          <Typography variant="caption" sx={{ color: '#4caf50', mt: 1, display: 'block' }}>
            ↑ 4% from last week
          </Typography>
        </Paper>
        <Paper sx={{ 
          bgcolor: 'rgba(31, 41, 55, 0.6)', 
          borderRadius: 2, 
          p: 1.5, 
          boxShadow: 2 
        }}>
          <Typography variant="caption" sx={{ color: 'text.secondary', mb: 0.5, display: 'block' }}>
            Avg Response Time
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white' }}>
            2.6h
          </Typography>
          <Typography variant="caption" sx={{ color: '#4caf50', mt: 1, display: 'block' }}>
            ↓ 15% from last week
          </Typography>
        </Paper>
      </Box>

      <Box 
        sx={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(2, 1fr)', 
          gap: 2, 
          mb: 2,
          flexGrow: 1
        }}
      >
        {/* Line chart for ticket trends */}
        <Paper sx={{ 
          bgcolor: 'rgba(31, 41, 55, 0.6)', 
          borderRadius: 2, 
          p: 1.5, 
          boxShadow: 2 
        }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'semibold', color: 'white', mb: 1 }}>
            Ticket Trends
          </Typography>
          <Box sx={{ height: 140 }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={ticketTrends}
                margin={{ top: 5, right: 10, left: -20, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
                <XAxis dataKey="name" stroke="rgba(255,255,255,0.5)" tick={{ fontSize: 10 }} />
                <YAxis stroke="rgba(255,255,255,0.5)" tick={{ fontSize: 10 }} />
                <Tooltip 
                  contentStyle={{ 
                    backgroundColor: '#1f2937', 
                    borderColor: '#374151',
                    color: 'white' 
                  }} 
                />
                <Legend wrapperStyle={{ fontSize: 10 }} />
                <Line 
                  type="monotone" 
                  dataKey="newTickets" 
                  name="New Tickets" 
                  stroke={lineColors[0]} 
                  strokeWidth={2}
                  dot={{ r: 0 }}
                  activeDot={{ r: 4 }} 
                />
                <Line 
                  type="monotone" 
                  dataKey="resolvedTickets" 
                  name="Resolved" 
                  stroke={lineColors[1]} 
                  strokeWidth={2}
                  dot={{ r: 0 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Paper>

        {/* Pie chart for ticket categories */}
        <Paper sx={{ 
          bgcolor: 'rgba(31, 41, 55, 0.6)', 
          borderRadius: 2, 
          p: 1.5, 
          boxShadow: 2 
        }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'semibold', color: 'white', mb: 1 }}>
            Ticket Categories
          </Typography>
          <Box sx={{ height: 140 }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={ticketCategories}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={50}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {ticketCategories.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                  ))}
                </Pie>
                <Tooltip 
                  contentStyle={{ 
                    backgroundColor: '#1f2937', 
                    borderColor: '#374151',
                    color: 'white' 
                  }} 
                />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default DashboardPreview;
