import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Grid,
  Paper,
  Chip,
  Card,
  useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { 
  BarChart, 
  Bar, 
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  LabelList
} from 'recharts';

const HowItWorksSection = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Data for charts
  const responseTimeData = [
    { name: 'Before', value: 24.5, label: '24.5h' },
    { name: 'After', value: 5.8, label: '5.8h' },
  ];

  const customerSatisfactionData = [
    { name: 'Jan', satisfaction: 72 },
    { name: 'Feb', satisfaction: 74 },
    { name: 'Mar', satisfaction: 78 },
    { name: 'Apr', satisfaction: 81 },
    { name: 'May', satisfaction: 86 },
    { name: 'Jun', satisfaction: 91 },
  ];

  const teamEfficiencyData = [
    { name: 'Tickets Resolved', before: 45, after: 78 },
    { name: 'Time Saved (hrs/week)', before: 12, after: 32 },
    { name: 'SLA Compliance (%)', before: 68, after: 94 },
  ];

  const maintenanceBreakdownData = [
    { name: 'Bug Fix', value: 25 },
    { name: 'Performance', value: 16 },
    { name: 'Infrastructure', value: 12 },
    { name: 'Stability', value: 10 },
    { name: 'Monitoring', value: 8 },
    { name: 'Other', value: 5 },
  ];

  const COLORS = ['#4d7bfc', '#8e9efa', '#b7fa37', '#33d6c9', '#ff7675', '#a0a0a0'];

  return (
    <Box sx={{ py: 10, position: 'relative' }}>
      {/* Background decorative element */}
      <Box 
        sx={{ 
          position: 'absolute',
          top: '10%',
          right: 0,
          width: '30%',
          height: '80%',
          background: 'radial-gradient(ellipse, rgba(183, 250, 55, 0.03) 0%, rgba(17, 24, 39, 0) 70%)',
          zIndex: -1
        }} 
      />
      
      <Container maxWidth="lg">
        <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ mb: 3 }}>
          How TicketSidekick Transforms Support
        </Typography>
        
        <Typography variant="h5" color="text.secondary" align="center" sx={{ mb: 6, maxWidth: 800, mx: 'auto', fontWeight: 400 }}>
          Your team's most precious resource is time. TicketSidekick helps you allocate it wisely with AI-powered assistance.
        </Typography>
        
        <Box sx={{ mb: 4 }}>
          <Accordion 
            expanded={expanded === 'panel1'} 
            onChange={handleChange('panel1')}
            sx={{ 
              backgroundColor: 'rgba(31, 41, 55, 0.7)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: '12px',
              mb: 2,
              '&:before': { display: 'none' },
              boxShadow: expanded === 'panel1' ? '0 8px 32px rgba(0, 0, 0, 0.2)' : 'none',
              transition: 'all 0.3s ease',
              overflow: 'hidden'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
              sx={{ 
                '& .MuiAccordionSummary-content': { 
                  alignItems: 'center',
                  py: 1
                }
              }}
            >
              <AutorenewIcon sx={{ color: 'primary.main', mr: 2, fontSize: 28 }} />
              <Box>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  1. Quantify the True Cost of Maintenance
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Real-time visibility into support workload and bottlenecks
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                      Analyze & Understand
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ color: 'text.secondary' }}>
                      TicketSidekick automatically categorizes support workload, revealing both official and unofficial work streams. Discover where your team is spending time and identify opportunities to reduce repetitive work.
                    </Typography>
                  </Box>
                  
                  <Box sx={{ mb: 2 }}>
                    <Chip 
                      label="32.3% of total work goes to maintenance" 
                      sx={{ 
                        bgcolor: 'rgba(77, 123, 252, 0.2)', 
                        color: 'primary.main',
                        fontWeight: 500,
                        mb: 2
                      }} 
                    />
                  </Box>
                  
                  <Card sx={{ 
                    p: 2, 
                    bgcolor: 'rgba(255, 255, 255, 0.03)',
                    border: '1px solid rgba(255, 255, 255, 0.05)',
                  }}>
                    <Typography variant="subtitle2" sx={{ color: 'secondary.main', fontWeight: 600, mb: 1 }}>
                      📈 RECOMMENDED IMPROVEMENT
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      Reduce Bug Fix time by 11% (2.7 FTE-days/month)
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      Add automated tests for common issues and use AI to suggest solutions for recurring problems.
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper sx={{ 
                    p: 3, 
                    height: '100%',
                    background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.4) 0%, rgba(31, 41, 55, 0.2) 100%)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: 4
                  }}>
                    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                      Maintenance Breakdown
                    </Typography>
                    <Box sx={{ height: 260 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={maintenanceBreakdownData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={90}
                            fill="#8884d8"
                            dataKey="value"
                            label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                          >
                            {maintenanceBreakdownData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip 
                            contentStyle={{ 
                              backgroundColor: '#1f2937', 
                              borderColor: '#374151',
                              color: 'white' 
                            }} 
                            formatter={(value) => [`${value}%`, 'Percentage']}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          
          <Accordion 
            expanded={expanded === 'panel2'} 
            onChange={handleChange('panel2')}
            sx={{ 
              backgroundColor: 'rgba(31, 41, 55, 0.7)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: '12px',
              mb: 2,
              '&:before': { display: 'none' },
              boxShadow: expanded === 'panel2' ? '0 8px 32px rgba(0, 0, 0, 0.2)' : 'none',
              transition: 'all 0.3s ease',
              overflow: 'hidden'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
              sx={{ 
                '& .MuiAccordionSummary-content': { 
                  alignItems: 'center',
                  py: 1
                }
              }}
            >
              <AccessTimeIcon sx={{ color: 'primary.main', mr: 2, fontSize: 28 }} />
              <Box>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  2. Maximize productive time
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Dramatic reduction in response times and workload
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                    Automate & Optimize
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ color: 'text.secondary' }}>
                      TicketSidekick automates repetitive tasks and provides AI-powered suggestions, dramatically reducing response times and allowing your team to focus on complex issues that truly require human expertise.
                    </Typography>
                  </Box>
                  
                  <Box sx={{ mb: 2 }}>
                    <Chip 
                      label="76% reduction in average response time" 
                      sx={{ 
                        bgcolor: 'rgba(183, 250, 55, 0.2)', 
                        color: 'secondary.main',
                        fontWeight: 500,
                        mb: 2
                      }} 
                    />
                  </Box>
                  
                  <Card sx={{ 
                    p: 2, 
                    bgcolor: 'rgba(255, 255, 255, 0.03)',
                    border: '1px solid rgba(255, 255, 255, 0.05)',
                  }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                        Time Saved Per Week:
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: '#4caf50', fontWeight: 600 }}>
                        20+ hours
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      Your team can reinvest this time in proactive improvements and innovation instead of repetitive support tasks.
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper sx={{ 
                    p: 3, 
                    height: '100%',
                    background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.4) 0%, rgba(31, 41, 55, 0.2) 100%)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: 4
                  }}>
                    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                      Average Response Time
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Hours until first meaningful response
                    </Typography>
                    <Box sx={{ height: 240 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          data={responseTimeData}
                          layout="vertical"
                          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} stroke="rgba(255,255,255,0.1)" />
                          <XAxis type="number" domain={[0, 30]} tick={{ fontSize: 12 }} stroke="rgba(255,255,255,0.5)" />
                          <YAxis dataKey="name" type="category" tick={{ fontSize: 14 }} stroke="rgba(255,255,255,0.5)" />
                          <Tooltip
                            contentStyle={{ 
                              backgroundColor: '#1f2937', 
                              borderColor: '#374151',
                              color: 'white' 
                            }}
                            formatter={(value) => [`${value} hours`, 'Response Time']}
                          />
                          <Bar 
                            dataKey="value" 
                            fill={theme.palette.primary.main}
                            radius={[0, 4, 4, 0]}
                          >
                            <LabelList dataKey="label" position="right" fill="#fff" fontSize={14} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          
          <Accordion 
            expanded={expanded === 'panel3'} 
            onChange={handleChange('panel3')}
            sx={{ 
              backgroundColor: 'rgba(31, 41, 55, 0.7)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: '12px',
              mb: 2,
              '&:before': { display: 'none' },
              boxShadow: expanded === 'panel3' ? '0 8px 32px rgba(0, 0, 0, 0.2)' : 'none',
              transition: 'all 0.3s ease',
              overflow: 'hidden'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
              sx={{ 
                '& .MuiAccordionSummary-content': { 
                  alignItems: 'center',
                  py: 1
                }
              }}
            >
              <QueryStatsIcon sx={{ color: 'primary.main', mr: 2, fontSize: 28 }} />
              <Box>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  3. Measure & Improve
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Continuously optimize performance with actionable insights
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                      Data-driven optimization
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ color: 'text.secondary' }}>
                      TicketSidekick's analytics engine continuously monitors your support operations, identifies trends, and suggests improvements to further optimize your team's efficiency and effectiveness.
                    </Typography>
                  </Box>
                  
                  <Box sx={{ mb: 2 }}>
                    <Chip 
                      label="73% improvement in team efficiency metrics" 
                      sx={{ 
                        bgcolor: 'rgba(77, 123, 252, 0.2)', 
                        color: 'primary.main',
                        fontWeight: 500,
                        mb: 2
                      }} 
                    />
                  </Box>
                  
                  <Card sx={{ 
                    p: 2, 
                    bgcolor: 'rgba(255, 255, 255, 0.03)',
                    border: '1px solid rgba(255, 255, 255, 0.05)',
                  }}>
                    <Typography variant="subtitle2" sx={{ color: 'secondary.main', fontWeight: 600, mb: 1 }}>
                      🚀 KEY OUTCOMES
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1 }}>
                      <Chip size="small" label="74% faster resolution" sx={{ bgcolor: 'rgba(77, 123, 252, 0.1)' }} />
                      <Chip size="small" label="94% SLA compliance" sx={{ bgcolor: 'rgba(77, 123, 252, 0.1)' }} />
                      <Chip size="small" label="26% cost reduction" sx={{ bgcolor: 'rgba(77, 123, 252, 0.1)' }} />
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      Continuous improvement with AI-powered recommendations tailored to your support patterns.
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper sx={{ 
                    p: 3, 
                    height: '100%',
                    background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.4) 0%, rgba(31, 41, 55, 0.2) 100%)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: 4
                  }}>
                    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                      Team Efficiency Comparison
                    </Typography>
                    <Box sx={{ height: 240 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          data={teamEfficiencyData}
                          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
                          <XAxis dataKey="name" stroke="rgba(255,255,255,0.5)" tick={{ fontSize: 12 }} />
                          <YAxis stroke="rgba(255,255,255,0.5)" tick={{ fontSize: 12 }} />
                          <Tooltip
                            contentStyle={{ 
                              backgroundColor: '#1f2937', 
                              borderColor: '#374151',
                              color: 'white' 
                            }}
                          />
                          <Legend wrapperStyle={{ fontSize: 12 }} />
                          <Bar dataKey="before" name="Before TicketSidekick" fill="#64748b" radius={[4, 4, 0, 0]} />
                          <Bar dataKey="after" name="After TicketSidekick" fill="#4d7bfc" radius={[4, 4, 0, 0]} />
                        </BarChart>
                      </ResponsiveContainer>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          
          <Accordion 
            expanded={expanded === 'panel4'} 
            onChange={handleChange('panel4')}
            sx={{ 
              backgroundColor: 'rgba(31, 41, 55, 0.7)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: '12px',
              '&:before': { display: 'none' },
              boxShadow: expanded === 'panel4' ? '0 8px 32px rgba(0, 0, 0, 0.2)' : 'none',
              transition: 'all 0.3s ease',
              overflow: 'hidden'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
              sx={{ 
                '& .MuiAccordionSummary-content': { 
                  alignItems: 'center',
                  py: 1
                }
              }}
            >
              <SentimentSatisfiedAltIcon sx={{ color: 'primary.main', mr: 2, fontSize: 28 }} />
              <Box>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  4. Delight Customers
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Consistently exceed customer expectations
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                      Elevate customer satisfaction
                    </Typography>
                    <Typography variant="body2" paragraph sx={{ color: 'text.secondary' }}>
                      Faster response times, more accurate solutions, and consistent support quality all contribute to higher customer satisfaction scores and improved retention rates.
                    </Typography>
                  </Box>
                  
                  <Box sx={{ mb: 2 }}>
                    <Chip 
                      label="Customer satisfaction improved by 26%" 
                      sx={{ 
                        bgcolor: 'rgba(183, 250, 55, 0.2)', 
                        color: 'secondary.main',
                        fontWeight: 500,
                        mb: 2
                      }} 
                    />
                  </Box>
                  
                  <Card sx={{ 
                    p: 2, 
                    bgcolor: 'rgba(255, 255, 255, 0.03)',
                    border: '1px solid rgba(255, 255, 255, 0.05)',
                  }}>
                    <Typography variant="subtitle2" sx={{ color: 'primary.main', fontWeight: 600, mb: 1 }}>
                      💬 CUSTOMER FEEDBACK
                    </Typography>
                    <Typography variant="body2" sx={{ fontStyle: 'italic', mb: 1.5 }}>
                      "The support team is now more responsive and provides more accurate solutions on the first try. It's like they can read our minds!"
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      — Maria Jenkins, Enterprise Customer
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper sx={{ 
                    p: 3, 
                    height: '100%',
                    background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.4) 0%, rgba(31, 41, 55, 0.2) 100%)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: 4
                  }}>
                    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                      Customer Satisfaction Trend
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      After TicketSidekick implementation
                    </Typography>
                    <Box sx={{ height: 240 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                          data={customerSatisfactionData}
                          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
                          <XAxis dataKey="name" stroke="rgba(255,255,255,0.5)" />
                          <YAxis domain={[65, 95]} stroke="rgba(255,255,255,0.5)" />
                          <Tooltip
                            contentStyle={{ 
                              backgroundColor: '#1f2937', 
                              borderColor: '#374151',
                              color: 'white' 
                            }}
                            formatter={(value) => [`${value}%`, 'Satisfaction Score']}
                          />
                          <Line 
                            type="monotone" 
                            dataKey="satisfaction" 
                            name="Satisfaction Score"
                            stroke={theme.palette.secondary.main} 
                            strokeWidth={3}
                            dot={{ r: 4, fill: theme.palette.secondary.main }}
                            activeDot={{ r: 6 }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
};

export default HowItWorksSection;
