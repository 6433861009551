import React from 'react'
import useDocumentHead from '../hooks/useDocumentHead'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function PrivacyPolicyPage() {
  useDocumentHead({
    title: 'TicketSidekick Privacy Policy',
    description: 'Read the privacy policy for TicketSidekick. Learn how we collect, use, and protect your information.',
  })

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy for TicketSidekick.com
        </Typography>

        <Typography paragraph>
          Practical Alignment AI ("we," "us," or "our") operates TicketSidekick.com (the "Service"). This page informs you of our policies regarding the collection, use, and
          disclosure of Personal Information when you use our Service.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Information Collection and Use
        </Typography>
        <Typography paragraph>
          We collect information to provide and improve our Service. We may collect information that your browser sends whenever you visit our Service ("Log Data"). This Log Data
          may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Service that you visit, the time and
          date of your visit, the time spent on those pages, and other statistics.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Use of Information
        </Typography>
        <Typography paragraph>
          Practical Alignment AI uses the collected data for various purposes:
          <ul>
            <li>To provide and maintain the Service</li>
            <li>To notify you about changes to our Service</li>
            <li>To provide customer support</li>
            <li>To improve the Service</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Data Security
        </Typography>
        <Typography paragraph>
          The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100%
          secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions about this Privacy Policy, please contact us at: <a href="mailto:support@TicketSidekick.com">support@TicketSidekick.com</a>
        </Typography>

        <Typography paragraph>This Privacy Policy is effective as of March 12, 2025.</Typography>
      </Box>
    </Container>
  )
}

export default PrivacyPolicyPage
