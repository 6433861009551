import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useDocumentHead from '../hooks/useDocumentHead'
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  InputAdornment,
  CircularProgress, // Import CircularProgress
  Backdrop, // Import Backdrop
} from '@mui/material'
import { Calculate, Person, Savings, AccessTime, TrendingUp, Warning, KeyboardArrowUp, KeyboardArrowDown, ArrowForward } from '@mui/icons-material'

import VisualizationDashboard from '../components/VisualizationDashboard'

// Constants for AI-driven improvement estimates (easily adjustable)
const ESTIMATED_HEADCOUNT_REDUCTION = 0.3 // 30% reduction
const ESTIMATED_MTTR_IMPROVEMENT = 0.45 // 45% improvement (new MTTR is 55% of original)
const Calculator = () => {
  const navigate = useNavigate()
  useDocumentHead({
    title: 'TicketSidekick Cost Savings Calculator',
    description: 'Calculate your potential cost savings with TicketSidekick. See how much you can save by automating your support operations.',
  })
  const [formData, setFormData] = useState({
    engineers: 2,
    engineerCost: 150000,
    incidents: 100,
    falsePositives: 30,
    timePerIncident: 15,
    mttr: 4,
  })
  const [results, setResults] = useState(null)
  const [showForm, setShowForm] = useState(true)
  const [showResultDetails, setShowResultDetails] = useState(true)
  const [showOverlay, setShowOverlay] = useState(false) // State for overlay visibility

  const handleInputChange = e => {
    const { id, value } = e.target
    // Convert the input value to a number.  The '+' is a shortcut for Number(value)
    const numericValue = +value

    setFormData({
      ...formData,
      [id]: numericValue,
    })
  }

  const calculateResults = () => {
    setShowOverlay(true) // Show overlay when calculation starts

    // Input validation
    if (
      isNaN(formData.engineers) ||
      formData.engineers < 1 ||
      formData.engineers > 1000 ||
      isNaN(formData.engineerCost) ||
      formData.engineerCost < 50000 ||
      formData.engineerCost > 500000 ||
      isNaN(formData.incidents) ||
      formData.incidents < 1 ||
      formData.incidents > 1000 ||
      isNaN(formData.falsePositives) ||
      formData.falsePositives < 0 ||
      formData.falsePositives > 100 ||
      isNaN(formData.timePerIncident) ||
      formData.timePerIncident < 1 ||
      formData.timePerIncident > 600 ||
      isNaN(formData.mttr) ||
      formData.mttr < 0.1 ||
      formData.mttr > 24
    ) {
      alert('Please enter valid input values within the allowed ranges.')
      setShowOverlay(false) // Hide overlay
      return // Stop the calculation
    }

    setTimeout(() => {
      // Simulate calculation delay and wrap in try...catch for error handling
      try {
        const { engineers, engineerCost, incidents, falsePositives, timePerIncident, mttr } = formData

        // Calculate current costs
        const annualEngineerCost = engineers * engineerCost
        const weeklyTriageMinutes = incidents * timePerIncident
        const weeklyTriageHours = weeklyTriageMinutes / 60
        const annualTriageHours = weeklyTriageHours * 52

        const falsePositiveIncidents = incidents * (falsePositives / 100)
        const falsePositiveMinutes = falsePositiveIncidents * timePerIncident * 52
        const falsePositiveHours = falsePositiveMinutes / 60
        const falsePositiveCost = (falsePositiveHours / (40 * 52)) * engineerCost // Assuming 40-hour work week

        // Calculate potential savings with TicketSidekick (using constants for clarity)
        const estimatedHeadcountReduction = engineers * ESTIMATED_HEADCOUNT_REDUCTION // Based on 30% reduction claim
        const estimatedAnnualSavings = estimatedHeadcountReduction * engineerCost
        const estimatedMttrNew = mttr * (1 - ESTIMATED_MTTR_IMPROVEMENT) // Based on 45% improvement claim
        const mttrImprovement = mttr - estimatedMttrNew
        const estimatedHoursSaved = annualTriageHours * 0.4 // Conservative estimate

        // 5-year projection
        const projection = []
        let cumulativeSavings = 0

        for (let year = 1; year <= 5; year++) {
          const costWithoutAutomation = annualEngineerCost * Math.pow(1.05, year - 1) // 5% yearly increase
          const costWithTicketSidekick = (annualEngineerCost - estimatedAnnualSavings) * Math.pow(1.03, year - 1) // 3% yearly increase
          const yearlySavings = costWithoutAutomation - costWithTicketSidekick
          cumulativeSavings += yearlySavings

          projection.push({
            year,
            withoutAutomation: costWithoutAutomation,
            withTicketSidekick: costWithTicketSidekick,
            yearlySavings,
            cumulativeSavings,
          })
        }

        setResults({
          annualCost: annualEngineerCost,
          weeklyHours: weeklyTriageHours,
          falsePositiveHours: falsePositiveHours,
          falsePositiveCost,
          headcountReduction: estimatedHeadcountReduction,
          annualSavings: estimatedAnnualSavings,
          mttrImprovement,
          mttrNew: estimatedMttrNew,
          hoursSaved: estimatedHoursSaved,
          projection,
        })
      } catch (error) {
        alert(`An error occurred during calculation: ${error.message}`)
        console.error(error) // Log the error for debugging
      } finally {
        setShowForm(false)
        setShowOverlay(false) // Hide overlay after calculation is done (or on error)
      }
    }, 2000) // 2 seconds delay for overlay
  }

  const formatCurrency = value => {
    // Using Intl.NumberFormat for better currency formatting
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
  }

  const formatNumber = value => {
    return Math.round(value).toLocaleString()
  }

  const StatCard = ({ icon, title, value, description, highlight }) => {
    const IconComponent = icon

    return (
      <Card
        sx={{
          height: '100%',
          backgroundColor: '#ffffff',
          borderRadius: 2,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            <Box p={1} borderRadius={1} bgcolor={highlight ? '#e7f0ff' : '#f0f0f0'} color={highlight ? '#1565c0' : '#555555'} mr={1}>
              <IconComponent />
            </Box>
            <Typography variant="h6" color="#333333" fontWeight={600}>
              {title}
            </Typography>
          </Box>
          <Typography variant="h4" color={highlight ? '#1565c0' : '#333333'} fontWeight={highlight ? 700 : 500} gutterBottom>
            {value}
          </Typography>
          <Typography variant="body1" color="#555555" fontWeight={500}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <Container maxWidth="lg" sx={{ bgcolor: '#1a202c', minHeight: '100vh', py: 4 }}>
      <Box mb={4}>
        <Typography variant="h4" component="h1" gutterBottom color="#ffffff" fontWeight={700}>
          Triage Efficiency Calculator
        </Typography>
        <Typography variant="subtitle1" color="#cbd5e0" paragraph>
          Quantify your current triage costs and see potential savings with TicketSidekick automation.
        </Typography>

        {showForm ? (
          <Card sx={{ mt: 4, bgcolor: '#2d3748', color: 'white' }}>
            <CardHeader
              title="Current Triage Workload"
              titleTypographyProps={{ variant: 'h5', color: 'white', fontWeight: 600 }}
              sx={{ bgcolor: '#2d3748', borderBottom: '1px solid #4a5568' }}
            />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box mb={1}>
                    <Typography variant="subtitle1" color="white" fontWeight={500}>
                      Number of triage engineers
                    </Typography>
                  </Box>
                  <TextField
                    id="engineers"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={formData.engineers}
                    onChange={handleInputChange}
                    InputProps={{
                      inputProps: { min: 1 },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white',
                        border: '1px solid #4a5568',
                      },
                      '& .MuiInputBase-input': {
                        color: '#000000', // Black text for maximum contrast
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box mb={1}>
                    <Typography variant="subtitle1" color="white" fontWeight={500}>
                      Average annual cost per engineer (fully loaded)
                    </Typography>
                  </Box>
                  <TextField
                    id="engineerCost"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={formData.engineerCost}
                    onChange={handleInputChange}
                    InputProps={{
                      inputProps: { min: 50000 },
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white',
                        border: '1px solid #4a5568',
                      },
                      '& .MuiInputBase-input': {
                        color: '#000000', // Black text for maximum contrast
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box mb={1}>
                    <Typography variant="subtitle1" color="white" fontWeight={500}>
                      Average number of incidents per week
                    </Typography>
                  </Box>
                  <TextField
                    id="incidents"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={formData.incidents}
                    onChange={handleInputChange}
                    InputProps={{ inputProps: { min: 1 } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white',
                        border: '1px solid #4a5568',
                      },
                      '& .MuiInputBase-input': {
                        color: '#000000', // Black text for maximum contrast
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box mb={1}>
                    <Typography variant="subtitle1" color="white" fontWeight={500}>
                      Percentage of incidents that are false positives (%)
                    </Typography>
                  </Box>
                  <TextField
                    id="falsePositives"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={formData.falsePositives}
                    onChange={handleInputChange}
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white',
                        border: '1px solid #4a5568',
                      },
                      '& .MuiInputBase-input': {
                        color: '#000000', // Black text for maximum contrast
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box mb={1}>
                    <Typography variant="subtitle1" color="white" fontWeight={500}>
                      Average time spent per incident (minutes)
                    </Typography>
                  </Box>
                  <TextField
                    id="timePerIncident"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={formData.timePerIncident}
                    onChange={handleInputChange}
                    InputProps={{ inputProps: { min: 1 } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white',
                        border: '1px solid #4a5568',
                      },
                      '& .MuiInputBase-input': {
                        color: '#000000', // Black text for maximum contrast
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box mb={1}>
                    <Typography variant="subtitle1" color="white" fontWeight={500}>
                      Current mean time to resolution (hours)
                    </Typography>
                  </Box>
                  <TextField
                    id="mttr"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={formData.mttr}
                    onChange={handleInputChange}
                    InputProps={{ inputProps: { min: 0.1, step: 0.1 } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white',
                        border: '1px solid #4a5568',
                      },
                      '& .MuiInputBase-input': {
                        color: '#000000', // Black text for maximum contrast
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Box mt={4} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: '#3182ce',
                    '&:hover': { bgcolor: '#2c5282' },
                    color: 'white',
                    fontWeight: 600,
                    textTransform: 'none',
                    py: 1.5,
                    px: 3,
                  }}
                  size="large"
                  onClick={calculateResults}
                  startIcon={<Calculate />}
                >
                  Calculate Efficiency
                </Button>
              </Box>
            </CardContent>
          </Card>
        ) : (
          <>
            {results && (
              <Box>
                {/* Summary at the top */}
                <Paper
                  elevation={3}
                  sx={{
                    mt: 4,
                    p: 4,
                    bgcolor: '#3182ce',
                    color: 'white',
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Potential with TicketSidekick
                  </Typography>
                  <Typography variant="h6" paragraph sx={{ fontWeight: 500 }}>
                    Based on your inputs, TicketSidekick could save your organization approximately{' '}
                    <Typography component="span" variant="h4" fontWeight="800">
                      {formatCurrency(results.annualSavings)}
                    </Typography>{' '}
                    annually while reducing mean time to resolution by{' '}
                    <Typography component="span" fontWeight="bold">
                      45%
                    </Typography>
                    .
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>
                    This would free up{' '}
                    <Typography component="span" fontWeight="bold">
                      {formatNumber(results.hoursSaved)} engineering hours
                    </Typography>{' '}
                    annually that could be redirected to product development.
                  </Typography>
                  <Box mt={3} display="flex" flexWrap="wrap" gap={2}>
                    <Button
                      size="large"
                      sx={{
                        bgcolor: 'white',
                        color: '#white',
                        '&:hover': { bgcolor: '#e2e8f0' },
                        fontWeight: 600,
                        textTransform: 'none',
                      }}
                      onClick={() => navigate('/contact')}
                      endIcon={<ArrowForward />}
                    >
                      Schedule Free Assessment
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{
                        color: 'white',
                        borderColor: 'white',
                        '&:hover': { borderColor: 'white', bgcolor: 'rgba(255, 255, 255, 0.1)' },
                        textTransform: 'none',
                      }}
                      onClick={() => setShowForm(true)}
                    >
                      Edit Inputs
                    </Button>
                  </Box>
                </Paper>

                {/* Key metrics */}
                <Grid container spacing={3} sx={{ mt: 3 }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                      icon={Person}
                      title="Headcount Reduction"
                      value={`${formatNumber(results.headcountReduction)} FTEs`}
                      description="Potential reduction in triage staff"
                      highlight={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatCard icon={Savings} title="Annual Savings" value={formatCurrency(results.annualSavings)} description="Projected annual cost savings" highlight={true} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                      icon={AccessTime}
                      title="MTTR Improvement"
                      value={`45% (-${formatNumber(results.mttrImprovement)} hrs)`}
                      description={`New MTTR: ${results.mttrNew.toFixed(1)} hrs`}
                      highlight={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                      icon={TrendingUp}
                      title="Hours Saved Annually"
                      value={`${formatNumber(results.hoursSaved)} hrs`}
                      description="Engineering hours redirected to development"
                      highlight={true}
                    />
                  </Grid>
                </Grid>

                {/* Charts Placeholder */}
                {/* Charts - Using our visualization component */}
                <Paper
                  elevation={1}
                  sx={{
                    p: 3,
                    mt: 4,
                    bgcolor: '#2d3748',
                    color: 'white',
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, color: 'white' }}>
                    Visualized Impact
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <VisualizationDashboard results={results} formData={formData} />
                  </Box>
                </Paper>

                {/* Current Costs Details (Collapsible) */}
                <Paper
                  elevation={1}
                  sx={{
                    mt: 4,
                    bgcolor: '#2d3748',
                    color: 'white',
                    borderRadius: 2,
                  }}
                >
                  <Button
                    onClick={() => setShowResultDetails(!showResultDetails)}
                    fullWidth
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: 2,
                      textTransform: 'none',
                      color: '#90cdf4',
                    }}
                  >
                    <Typography variant="h6" align="left" sx={{ fontWeight: 600 }}>
                      Current Triage Costs Details
                    </Typography>
                    <IconButton size="small" sx={{ color: '#90cdf4' }}>
                      {showResultDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Button>

                  <Collapse in={showResultDetails}>
                    <Divider sx={{ bgcolor: '#4a5568' }} />
                    <Box p={3}>
                      <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={6} md={3}>
                          <StatCard
                            icon={Savings}
                            title="Annual Triage Cost"
                            value={formatCurrency(results.annualCost)}
                            description="Total annual spend on triage engineering"
                            highlight={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <StatCard
                            icon={AccessTime}
                            title="Weekly Triage Hours"
                            value={`${formatNumber(results.weeklyHours)} hrs`}
                            description="Engineering time spent on triage weekly"
                            highlight={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <StatCard
                            icon={Warning}
                            title="False Positive Cost"
                            value={formatCurrency(results.falsePositiveCost)}
                            description="Annual cost of handling false positives"
                            highlight={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <StatCard
                            icon={AccessTime}
                            title="False Positive Hours"
                            value={`${formatNumber(results.falsePositiveHours)} hrs`}
                            description="Annual time spent on false positives"
                            highlight={false}
                          />
                        </Grid>
                      </Grid>

                      {/* 5-Year Projection Table */}
                      <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                        5-Year Projection
                      </Typography>
                      <TableContainer
                        sx={{
                          bgcolor: '#1a202c',
                          borderRadius: 1,
                          border: '1px solid #4a5568',
                        }}
                      >
                        <Table
                          sx={{
                            '& .MuiTableCell-root': {
                              color: 'white',
                              borderColor: '#4a5568',
                            },
                          }}
                        >
                          <TableHead sx={{ bgcolor: '#2d3748' }}>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Year</TableCell>
                              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Without Automation</TableCell>
                              <TableCell sx={{ fontWeight: 600, color: 'white' }}>With TicketSidekick</TableCell>
                              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Annual Savings</TableCell>
                              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Cumulative Savings</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {results.projection.map((row, index) => (
                              <TableRow
                                key={row.year}
                                sx={{
                                  '&:nth-of-type(odd)': { bgcolor: '#2d3748' },
                                  '&:nth-of-type(even)': { bgcolor: '#1a202c' },
                                }}
                              >
                                <TableCell component="th" scope="row" sx={{ fontWeight: 500, color: 'white' }}>
                                  Year {row.year}
                                </TableCell>
                                <TableCell sx={{ color: 'white' }}>{formatCurrency(row.withoutAutomation)}</TableCell>
                                <TableCell sx={{ color: 'white' }}>{formatCurrency(row.withTicketSidekick)}</TableCell>
                                <TableCell sx={{ color: 'white' }}>{formatCurrency(row.yearlySavings)}</TableCell>
                                <TableCell sx={{ color: '#63b3ed', fontWeight: 700 }}>{formatCurrency(row.cumulativeSavings)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Collapse>
                </Paper>

                <Box display="flex" justifyContent="center" mt={4}>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      bgcolor: '#3182ce',
                      '&:hover': { bgcolor: '#2c5282' },
                      color: 'white',
                      fontWeight: 600,
                      textTransform: 'none',
                      py: 1.5,
                      px: 3,
                    }}
                    onClick={() => navigate('/contact')}
                    endIcon={<ArrowForward />}
                  >
                    Schedule Free Assessment
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
      {/* Backdrop and Overlay */}
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={showOverlay}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2, color: 'white' }}>
            Calculating your savings with TicketSidekick...
          </Typography>
        </Box>
      </Backdrop>
    </Container>
  )
}

export default Calculator
