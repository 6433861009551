import React, { useState } from 'react'
import useDocumentHead from '../hooks/useDocumentHead'
import { Box, Container, Typography, TextField, Button, Grid, Paper, MenuItem, Divider, Card, CardContent, Link, useTheme, Alert, CheckCircleOutline } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import LocationOnIcon from '@mui/icons-material/LocationOn'

// Validation schema for form
const ContactSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  companyName: Yup.string().required('Required'),
  companySize: Yup.string().required('Required'),
  ticketingSystem: Yup.string().required('Required'),
  message: Yup.string().min(10, 'Please provide more details').required('Required'),
})

const companySizes = ['1-10 employees', '11-50 employees', '51-200 employees', '201-500 employees', '501-1000 employees', '1000+ employees']

const ticketingSystems = ['Zendesk', 'Freshdesk', 'ServiceNow', 'Jira Service Desk', 'Salesforce Service Cloud', 'HubSpot Service Hub', 'Other (Please specify in message)']

const ContactPage = () => {
  const theme = useTheme()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formError, setFormError] = useState(null)

  useDocumentHead({
    title: 'Contact TicketSidekick | Request a Demo & Pricing',
    description: 'Get in touch with TicketSidekick for a customized demo and pricing information. Transform your support operations with AI.',
  })

  return (
    <Box>
      {/* Header section */}
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          py: 6,
          mb: 6,
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h2" component="h1" gutterBottom align="center">
            Get In Touch
          </Typography>
          <Typography variant="h5" align="center" sx={{ maxWidth: 700, mx: 'auto' }}>
            Contact us for a customized demo and pricing tailored to your support needs
          </Typography>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ mb: 8 }}>
        <Grid container spacing={4}>
          {/* Contact form */}
          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
              {formSubmitted ? (
                <Box sx={{ textAlign: 'center', py: 4 }}>
                  <CheckCircleOutlineIcon sx={{ fontSize: 64, color: 'success.main', mb: 2 }} />

                  <Typography variant="h4" component="h2" gutterBottom>
                    Thank You for Your Interest!
                  </Typography>

                  <Typography variant="body1" paragraph sx={{ maxWidth: 600, mx: 'auto', mb: 3 }}>
                    We've received your request and a member of our team will reach out to you within one business day to discuss how TicketSidekick can optimize your support
                    workflow.
                  </Typography>

                  <Button variant="contained" color="primary" onClick={() => setFormSubmitted(false)} sx={{ mt: 2 }}>
                    Submit Another Request
                  </Button>
                </Box>
              ) : (
                <>
                  <Typography variant="h4" component="h2" gutterBottom>
                    Request Information
                  </Typography>
                  <Typography variant="body1" paragraph color="text.secondary">
                    Fill out the form below and our team will get back to you within one business day.
                  </Typography>

                  {formError && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                      {formError}
                    </Alert>
                  )}

                  <Formik
                    initialValues={{
                      firstName: '',
                      lastName: '',
                      email: '',
                      companyName: '',
                      companySize: '',
                      ticketingSystem: '',
                      message: '',
                    }}
                    validationSchema={ContactSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      console.log('Submitting form...', values)

                      fetch('https://api.ticketsidekick.com/contact', {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(values),
                      })
                        .then(response => {
                          console.log('Response received:', response.status)
                          if (!response.ok) {
                            throw new Error('Network response was not ok: ' + response.status)
                          }
                          return response.json()
                        })
                        .then(data => {
                          console.log('Success:', data)
                          resetForm()
                          setFormSubmitted(true)
                          setFormError(null)
                        })
                        .catch(error => {
                          console.error('Error submitting form:', error)
                          setFormError('There was an error submitting your request. Please try again or contact us directly at info@ticketsidekick.com')
                        })
                        .finally(() => {
                          setSubmitting(false)
                        })
                    }}
                  >
                    {({ errors, touched, isSubmitting, values, handleChange, handleBlur }) => (
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              id="firstName"
                              name="firstName"
                              label="First Name"
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.firstName && Boolean(errors.firstName)}
                              helperText={touched.firstName && errors.firstName}
                              margin="normal"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              id="lastName"
                              name="lastName"
                              label="Last Name"
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.lastName && Boolean(errors.lastName)}
                              helperText={touched.lastName && errors.lastName}
                              margin="normal"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="email"
                              name="email"
                              label="Email Address"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.email && Boolean(errors.email)}
                              helperText={touched.email && errors.email}
                              margin="normal"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="companyName"
                              name="companyName"
                              label="Company Name"
                              value={values.companyName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.companyName && Boolean(errors.companyName)}
                              helperText={touched.companyName && errors.companyName}
                              margin="normal"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              id="companySize"
                              name="companySize"
                              select
                              label="Company Size"
                              value={values.companySize}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.companySize && Boolean(errors.companySize)}
                              helperText={touched.companySize && errors.companySize}
                              margin="normal"
                              variant="outlined"
                            >
                              {companySizes.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              id="ticketingSystem"
                              name="ticketingSystem"
                              select
                              label="Current Ticketing System"
                              value={values.ticketingSystem}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.ticketingSystem && Boolean(errors.ticketingSystem)}
                              helperText={touched.ticketingSystem && errors.ticketingSystem}
                              margin="normal"
                              variant="outlined"
                            >
                              {ticketingSystems.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="message"
                              name="message"
                              label="Tell us about your support needs"
                              multiline
                              rows={4}
                              value={values.message}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.message && Boolean(errors.message)}
                              helperText={touched.message && errors.message}
                              margin="normal"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" size="large" disabled={isSubmitting} sx={{ mt: 2 }}>
                              {isSubmitting ? 'Submitting...' : 'Submit Request'}
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </Paper>
          </Grid>

          {/* Contact information */}
          <Grid item xs={12} md={4}>
            <Card sx={{ mb: 3, height: '100%' }}>
              <CardContent sx={{ p: 3 }}>
                <Typography variant="h4" component="h2" gutterBottom>
                  Contact Information
                </Typography>
                <Divider sx={{ mb: 3 }} />

                <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 3 }}>
                  <EmailIcon sx={{ mr: 2, color: theme.palette.primary.main }} />
                  <Box>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Email
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <Link href="mailto:info@ticketsidekick.com" color="inherit">
                        info@ticketsidekick.com
                      </Link>
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 3 }}>
                  <PhoneIcon sx={{ mr: 2, color: theme.palette.primary.main }} />
                  <Box>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Phone
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <Link href="tel:+14157549214" color="inherit">
                        (415) 754-9214
                      </Link>
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <LocationOnIcon sx={{ mr: 2, color: theme.palette.primary.main }} />
                  <Box>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Office
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      5900 Balcones Dr Ste 100
                      <br />
                      Austin, TX 78731-4298
                      <br />
                      United States
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ContactPage
