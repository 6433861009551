import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useDocumentHead from '../hooks/useDocumentHead'
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Paper,
  Tabs,
  Tab,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import BuildIcon from '@mui/icons-material/Build'
import SecurityIcon from '@mui/icons-material/Security'
import SchoolIcon from '@mui/icons-material/School'
import DashboardIcon from '@mui/icons-material/Dashboard'
import TranslateIcon from '@mui/icons-material/Translate'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DashboardPreview from '../components/DashboardPreview'

// Features data
const features = [
  {
    id: 'automation',
    icon: <AutorenewIcon fontSize="large" color="primary" />,
    title: 'Ticket Automation',
    description: 'Streamline your support workflow with AI-powered ticket handling',
    benefits: [
      'Automatic ticket categorization and prioritization',
      'Smart routing to the right department or specialist',
      'Automated responses for common inquiries',
      'Proactive issue detection and resolution suggestions',
      'Custom workflow triggers and actions',
    ],
    details:
      'Our AI engine analyzes incoming tickets in real-time, understanding context and intent to automatically categorize, prioritize, and route them to the right team members. For common inquiries, TicketSidekick can draft responses for quick review and approval, saving valuable time for your support engineers.',
  },
  {
    id: 'analytics',
    icon: <QueryStatsIcon fontSize="large" color="primary" />,
    title: 'Advanced Analytics',
    description: 'Gain deep insights into your support operations',
    benefits: [
      'Real-time performance dashboards',
      'Trend analysis and predictive modeling',
      'Customer satisfaction metrics',
      'Team productivity tracking',
      'Custom report generation',
    ],
    details:
      'Transform your support data into actionable insights with our comprehensive analytics suite. Track key metrics in real-time, identify emerging trends, and understand the true drivers of customer satisfaction. Our predictive analytics can even help you anticipate support volume spikes and resource needs.',
  },
  {
    id: 'integration',
    icon: <IntegrationInstructionsIcon fontSize="large" color="primary" />,
    title: 'Seamless Integration',
    description: 'Works with your existing systems and workflows',
    benefits: [
      'Pre-built connectors for popular ticketing systems',
      'Custom API integration capabilities',
      'Knowledge base connectivity',
      'CRM and customer data platform integration',
      'Single sign-on (SSO) support',
    ],
    details:
      'TicketSidekick integrates smoothly with your existing tech stack. We offer pre-built connectors for popular platforms like Zendesk, Freshdesk, ServiceNow, and Jira Service Desk, as well as custom API options for proprietary systems. Your knowledge bases, CRM data, and customer information can all be accessed seamlessly.',
  },
  {
    id: 'customization',
    icon: <BuildIcon fontSize="large" color="primary" />,
    title: 'Customizable Workflows',
    description: 'Tailor processes to your specific support requirements',
    benefits: ['Visual workflow builder', 'Custom trigger conditions', 'Automated action sequences', 'Role-based permissions and views', 'Conditional logic and branching'],
    details:
      'Every support team is unique, which is why TicketSidekick offers powerful customization options. Our visual workflow builder lets you create custom processes that match exactly how your team works. Define specific triggers, conditions, and actions to automate complex workflows without coding.',
  },
  {
    id: 'security',
    icon: <SecurityIcon fontSize="large" color="primary" />,
    title: 'Enterprise-Grade Security',
    description: 'Keep customer data secure with advanced protection',
    benefits: ['SOC 2 Type II compliance', 'End-to-end encryption', 'Role-based access controls', 'Audit logging and monitoring', 'Regular security assessments'],
    details:
      "We take security seriously. TicketSidekick is built with enterprise-grade security at its core, including end-to-end encryption, comprehensive access controls, and detailed audit logs. We're SOC 2 Type II compliant and conduct regular security assessments to ensure your data remains protected.",
  },
  {
    id: 'collaboration',
    icon: <SchoolIcon fontSize="large" color="primary" />,
    title: 'Human-AI Collaboration',
    description: "Augment your team's capabilities with AI assistance",
    benefits: [
      'AI-suggested responses and solutions',
      'Real-time knowledge recommendations',
      'Automatic documentation generation',
      'Skill-based routing and expertise matching',
      'Performance coaching and improvement suggestions',
    ],
    details:
      'TicketSidekick works alongside your support team, not in place of them. Our AI suggests relevant knowledge articles, draft responses, and potential solutions based on ticket context, helping agents resolve issues faster. The system learns from successful resolutions, continuously improving its recommendations.',
  },
]

// Integration platforms
const integrationPlatforms = [
  'Zendesk',
  'Freshdesk',
  'ServiceNow',
  'Jira Service Desk',
  'Salesforce Service Cloud',
  'HubSpot Service Hub',
  'Microsoft Dynamics',
  'Intercom',
  'Help Scout',
  'LiveAgent',
]

// FAQ data
const faqs = [
  {
    question: 'How long does implementation typically take?',
    answer:
      'Implementation timelines vary based on the complexity of your existing systems and customization needs. Typically, basic implementation can be completed in 2-4 weeks, while more complex integrations may take 6-8 weeks. Our team works closely with you throughout the process to ensure a smooth transition.',
  },
  {
    question: 'How does pricing work?',
    answer:
      'Our pricing is customized based on several factors including team size, ticket volume, required features, and integration complexity. We believe in transparent pricing with no hidden fees. Contact our sales team for a personalized quote based on your specific needs.',
  },
  {
    question: 'What kind of support do you provide during and after implementation?',
    answer:
      'We provide comprehensive support throughout the implementation process, including dedicated implementation specialists, training sessions, and documentation. After launch, our customer success team offers ongoing support, regular check-ins, and optimization recommendations to ensure you get maximum value.',
  },
  {
    question: 'Can TicketSidekick handle multiple languages?',
    answer:
      'Yes, our platform supports multiple languages for both incoming tickets and response suggestions. The AI engine can detect the language of incoming queries and provide appropriate responses in the same language, making it ideal for global support operations.',
  },
]

const FeaturesPage = () => {
  const theme = useTheme()
  const [selectedTab, setSelectedTab] = React.useState(0)

  useDocumentHead({
    title: 'TicketSidekick Features | AI Automation, Analytics, & Integrations',
    description: "Explore TicketSidekick's features: AI-powered automation, advanced analytics, seamless integrations, and enterprise-grade security.",
  })

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <Box sx={{ overflow: 'hidden' }}>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          color: 'white',
          py: { xs: 10, md: 14 },
          overflow: 'hidden',
        }}
      >
        {/* Background gradient */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle at top right, rgba(77, 123, 252, 0.15) 0%, rgba(17, 24, 39, 0) 70%)',
          }}
        />

        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              component="h1"
              gutterBottom
              align="center"
              sx={{
                fontSize: { xs: '2.5rem', md: '3.5rem' },
                mb: 3,
              }}
            >
              TicketSidekick Features
            </Typography>
            <Typography variant="h5" align="center" sx={{ maxWidth: 800, mx: 'auto', fontWeight: 400 }}>
              Powerful tools to transform your support operations and enhance customer experiences
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* Feature Overview */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 20px 30px rgba(0, 0, 0, 0.3)',
                  },
                }}
              >
                <CardContent sx={{ padding: 4, flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>{feature.icon}</Box>
                  <Typography variant="h5" component="h3" gutterBottom align="center">
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" paragraph align="center" color="text.secondary">
                    {feature.description}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <List dense>
                      {feature.benefits.slice(0, 3).map((benefit, idx) => (
                        <ListItem key={idx} sx={{ px: 0 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <CheckCircleIcon color="secondary" fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primary={benefit}
                            primaryTypographyProps={{
                              variant: 'body2',
                              color: 'text.secondary',
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Detailed Feature Tabs */}
      <Box sx={{ py: 10, position: 'relative' }}>
        {/* Background gradient */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.95) 0%, rgba(17, 24, 39, 0.95) 100%)',
            zIndex: -1,
          }}
        />

        <Container maxWidth="lg">
          <Typography variant="h3" component="h2" gutterBottom align="center" sx={{ mb: 6 }}>
            Explore Our Features
          </Typography>

          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'rgba(255, 255, 255, 0.1)',
              mb: 5,
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                '& .MuiTab-root': {
                  minWidth: 'unset',
                  color: 'rgba(255, 255, 255, 0.6)',
                  py: 2,
                },
                '& .Mui-selected': {
                  color: 'white',
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              {features.map((feature, index) => (
                <Tab key={index} icon={React.cloneElement(feature.icon, { fontSize: 'medium' })} label={feature.title} id={`tab-${index}`} aria-controls={`tabpanel-${index}`} />
              ))}
            </Tabs>
          </Box>

          {features.map((feature, index) => (
            <div key={index} role="tabpanel" hidden={selectedTab !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
              {selectedTab === index && (
                <Grid container spacing={6} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Typography variant="h4" component="h3" gutterBottom>
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" paragraph color="text.secondary">
                      {feature.details}
                    </Typography>
                    <List>
                      {feature.benefits.map((benefit, idx) => (
                        <ListItem key={idx} sx={{ px: 0, pb: 1.5 }}>
                          <ListItemIcon>
                            <CheckCircleIcon color="secondary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={benefit}
                            primaryTypographyProps={{
                              color: 'text.secondary',
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={16}
                      sx={{
                        p: 0,
                        borderRadius: 4,
                        background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.7) 0%, rgba(31, 41, 55, 0.5) 100%)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        height: 520, // Increased from 350px to 520px
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 20px 40px rgba(0, 0, 0, 0.3)',
                        overflow: 'hidden',
                      }}
                    >
                      <DashboardPreview />
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </div>
          ))}
        </Container>
      </Box>

      {/* Integration Section */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Typography variant="h3" component="h2" gutterBottom align="center">
          Seamless Integration
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" sx={{ mb: 6, maxWidth: 800, mx: 'auto', fontWeight: 400 }}>
          TicketSidekick works with your existing tools and systems
        </Typography>

        <Grid container spacing={2} justifyContent="center" sx={{ mb: 6 }}>
          {integrationPlatforms.map((platform, index) => (
            <Grid item key={index}>
              <Card
                sx={{
                  px: 3,
                  py: 2,
                  minWidth: 140,
                  textAlign: 'center',
                  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <Typography variant="subtitle1">{platform}</Typography>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body1" paragraph color="text.secondary">
            Don't see your platform? We offer custom integrations for virtually any system.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            component={RouterLink}
            to="/contact"
            sx={{
              py: 1.5,
              px: 3,
              borderColor: 'rgba(77, 123, 252, 0.5)',
              '&:hover': {
                borderColor: 'primary.main',
              },
            }}
          >
            Ask About Custom Integrations
          </Button>
        </Box>
      </Container>

      {/* Use Cases */}
      <Box sx={{ py: 10, position: 'relative' }}>
        {/* Background gradient */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.95) 0%, rgba(17, 24, 39, 0.95) 100%)',
            zIndex: -1,
          }}
        />

        <Container maxWidth="lg">
          <Typography variant="h3" component="h2" gutterBottom align="center" sx={{ mb: 6 }}>
            Use Cases
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardContent sx={{ p: 4, flexGrow: 1 }}>
                  <DashboardIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    Enterprise Support Teams
                  </Typography>
                  <Typography variant="body1" paragraph color="text.secondary">
                    Handle high-volume tickets across multiple product lines and geographies with intelligent routing and prioritization.
                  </Typography>
                  <Typography variant="subtitle2" sx={{ mb: 2, color: theme.palette.primary.main, fontWeight: 600 }}>
                    KEY BENEFITS:
                  </Typography>
                  <List dense>
                    <ListItem sx={{ px: 0, pb: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Reduced response times"
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.secondary',
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ px: 0, pb: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Higher first-contact resolution rates"
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.secondary',
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ px: 0 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Cross-team knowledge sharing"
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.secondary',
                        }}
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardContent sx={{ p: 4, flexGrow: 1 }}>
                  <TranslateIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    Global Support Operations
                  </Typography>
                  <Typography variant="body1" paragraph color="text.secondary">
                    Support customers in multiple languages and time zones with 24/7 AI assistance and smart handoffs between regional teams.
                  </Typography>
                  <Typography variant="subtitle2" sx={{ mb: 2, color: theme.palette.primary.main, fontWeight: 600 }}>
                    KEY BENEFITS:
                  </Typography>
                  <List dense>
                    <ListItem sx={{ px: 0, pb: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Multilingual support capabilities"
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.secondary',
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ px: 0, pb: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Smooth shift transitions"
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.secondary',
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ px: 0 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Consistent customer experience"
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.secondary',
                        }}
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardContent sx={{ p: 4, flexGrow: 1 }}>
                  <VerifiedUserIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                  <Typography variant="h5" component="h3" gutterBottom>
                    Highly Regulated Industries
                  </Typography>
                  <Typography variant="body1" paragraph color="text.secondary">
                    Meet compliance requirements while improving support efficiency in finance, healthcare, and other regulated sectors.
                  </Typography>
                  <Typography variant="subtitle2" sx={{ mb: 2, color: theme.palette.primary.main, fontWeight: 600 }}>
                    KEY BENEFITS:
                  </Typography>
                  <List dense>
                    <ListItem sx={{ px: 0, pb: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Comprehensive audit trails"
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.secondary',
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ px: 0, pb: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Secure handling of sensitive data"
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.secondary',
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ px: 0 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Compliance-friendly workflows"
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.secondary',
                        }}
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* FAQ Section */}
      <Container maxWidth="md" sx={{ py: 10 }}>
        <Typography variant="h3" component="h2" gutterBottom align="center" sx={{ mb: 6 }}>
          Frequently Asked Questions
        </Typography>

        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            sx={{
              mb: 2,
              background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: '8px !important',
              '&:before': { display: 'none' },
              overflow: 'hidden',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{ px: 3, py: 1.5 }}
            >
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 3, pb: 3 }}>
              <Typography variant="body1" color="text.secondary">
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>

      {/* CTA Section */}
      <Box
        sx={{
          py: 10,
          background: 'linear-gradient(135deg, rgba(77, 123, 252, 0.9) 0%, rgba(56, 92, 196, 0.9) 100%)',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%',
            background: 'radial-gradient(circle at bottom right, rgba(183, 250, 55, 0.3) 0%, rgba(17, 24, 39, 0) 50%)',
            zIndex: 0,
          }}
        />
        <Container maxWidth="md" sx={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>
          <Typography variant="h2" component="h2" gutterBottom>
            Ready to See TicketSidekick in Action?
          </Typography>
          <Typography variant="h5" sx={{ mb: 6, opacity: 0.9, fontWeight: 400 }}>
            Contact us for a personalized demo tailored to your support needs
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component={RouterLink}
            to="/contact"
            endIcon={<ArrowForwardIcon />}
            sx={{
              py: 1.5,
              px: 4,
              fontSize: '1.1rem',
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
            }}
          >
            Request a Demo
          </Button>
        </Container>
      </Box>
    </Box>
  )
}

export default FeaturesPage
