import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useDocumentHead from '../hooks/useDocumentHead'
import { Box, Container, Typography, Grid, Card, CardContent, Button, Divider, Paper, Chip, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WorkIcon from '@mui/icons-material/Work'
import CodeIcon from '@mui/icons-material/Code'
import PsychologyIcon from '@mui/icons-material/Psychology'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

// Job listings
const jobListings = [
  {
    id: 'vibe-coder',
    title: 'Vibe Coder',
    department: 'Product Experience',
    location: 'Remote (US)',
    type: 'Full-time',
    featured: true,
    emoji: '✨',
    description:
      "We're looking for a Vibe Coder who just gets the modern software aesthetic—someone who can turn ✨ vibes ✨ into clean, engaging UI components. If you know how to make an interface hit different, we need you. You'll be the moment",
    responsibilities: [
      'Create UI components that pass the vibe check',
      'Collaborate with our UX team to craft experiences that are lowkey fire',
      "Optimize performance so our app isn't giving major lag energy",
      'Develop accessible interfaces that are inclusive (no cap)',
      'Stay on top of digital culture trends and aesthetics',
    ],
    requirements: [
      'Proven experience with React and modern frontend frameworks (3+ years)',
      'A portfolio that proves your design sensibility is on point',
      'A sharp eye for digital culture, trends, and aesthetics',
      'Knowledge of accessibility standards (WCAG)',
      'Ability to communicate effectively with different teams',
    ],
    perks: [
      'Fully remote position with flexible hours',
      'Competitive salary and equity package',
      'Unlimited PTO (that we actually encourage you to take)',
      'Annual tech stipend for your setup',
      'Mental health days and wellness benefits',
      'Pet-friendly virtual office culture',
    ],
  },
  {
    id: 'chief-technology-officer',
    title: 'Chief Technology Officer',
    department: 'Technology & Engineering',
    location: 'Remote (US)',
    type: 'Full-time',
    featured: true,
    emoji: '🚀',
    description:
      "TicketSideKick is transforming customer support with our AI-augmented platform that breaks organizational silos and enables exceptional customer experiences at scale. We're looking for a visionary CTO to lead our technology strategy and drive the evolution of our human-AI collaboration platform. You'll be at the forefront of creating the first autonomous, agentic facilitator for issue resolution that delivers measurable ROI while preserving the human touch customers value.",
    responsibilities: [
      "Define and execute the technical vision for TicketSideKick's AI augmentation platform",
      'Lead architecture decisions that balance innovation with pragmatic implementation',
      'Build and mentor high-performing engineering teams across AI, backend, and integration domains',
      'Drive our AI roadmap from core augmentation capabilities to advanced analytics and predictive insights',
      'Oversee development of seamless integrations with existing ticketing systems',
      'Ensure scalability, security, and compliance for enterprise and regulated industry customers',
      'Collaborate with product and go-to-market teams to align technology with customer needs',
      'Establish engineering best practices and KPIs to measure team effectiveness',
    ],
    requirements: [
      '10+ years of progressive technology leadership experience, with 5+ years in senior leadership roles',
      'Proven track record building AI/ML-powered products at scale, particularly in enterprise SaaS',
      'Strong technical background with hands-on experience in Python, Go, and modern cloud architectures',
      'Deep understanding of LLMs, agentic AI systems, and their practical application to business problems',
      'Experience with AWS services, particularly Foundation Models and other AI/ML services',
      'Track record of successful integrations with enterprise systems and APIs',
      'Strong business acumen with ability to translate technology capabilities into customer value',
      'Excellent communication skills to effectively engage with technical and non-technical stakeholders',
    ],
    preferred: [
      'Experience in customer support, IT service management, or related domains',
      'Background in building systems for regulated industries (finance, healthcare)',
      'Experience with data analytics platforms and deriving actionable insights from support data',
      'Track record of innovation in human-AI collaboration models',
      'Published research or patents in relevant technical areas',
      'Experience scaling engineering organizations from startup to growth stage',
    ],
    perks: [
      'Opportunity to shape the future of customer support technology',
      'Competitive salary and meaningful equity in a high-growth startup',
      'Remote-first culture with flexible work arrangements',
      'Comprehensive healthcare benefits',
      'Professional development budget and learning opportunities',
      'Regular team offsites and collaboration events',
    ],
  },
]

const CareersPage = () => {
  const theme = useTheme()

  useDocumentHead({
    title: 'Careers at TicketSidekick | Join Our Team',
    description: 'Explore career opportunities at TicketSidekick and help us transform customer support with AI. We are hiring!',
  })

  return (
    <Box sx={{ overflow: 'hidden' }}>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          color: 'white',
          py: { xs: 10, md: 14 },
          overflow: 'hidden',
        }}
      >
        {/* Background gradient */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle at top right, rgba(77, 123, 252, 0.15) 0%, rgba(17, 24, 39, 0) 70%)',
          }}
        />

        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Typography
                variant="h1"
                component="h1"
                gutterBottom
                sx={{
                  fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' },
                  lineHeight: 1.1,
                  mb: 3,
                }}
              >
                Join Our Team
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  mb: 5,
                  opacity: 0.9,
                  maxWidth: '600px',
                  fontWeight: 400,
                }}
              >
                Help us transform support operations with AI that empowers human capabilities. We're building the future of customer support, and we want you to be a part of it.
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  position: 'relative',
                  textAlign: 'center',
                  mt: { xs: 4, md: 0 },
                }}
              >
                <Card
                  sx={{
                    p: 4,
                    borderRadius: 4,
                    background: 'linear-gradient(135deg, rgba(31, 41, 55, 0.7) 0%, rgba(31, 41, 55, 0.5) 100%)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.3)',
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Why Work With Us?
                  </Typography>
                  <List>
                    <ListItem sx={{ px: 0, py: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary="Meaningful work with cutting-edge AI" />
                    </ListItem>
                    <ListItem sx={{ px: 0, py: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary="Remote-first culture built on trust" />
                    </ListItem>
                    <ListItem sx={{ px: 0, py: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary="Competitive compensation and benefits" />
                    </ListItem>
                    <ListItem sx={{ px: 0, py: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary="A high-performance team built on merit, excellence, and accountability." />
                    </ListItem>
                  </List>
                </Card>

                {/* Decorative elements */}
                <Box
                  sx={{
                    position: 'absolute',
                    width: 120,
                    height: 120,
                    borderRadius: '50%',
                    background: 'rgba(77, 123, 252, 0.3)',
                    filter: 'blur(40px)',
                    top: -40,
                    right: -20,
                    zIndex: -1,
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    background: 'rgba(183, 250, 55, 0.3)',
                    filter: 'blur(30px)',
                    bottom: -30,
                    left: 20,
                    zIndex: -1,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Open Positions */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ mb: 2 }}>
          Open Positions
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" sx={{ mb: 6, maxWidth: 800, mx: 'auto', fontWeight: 400 }}>
          Join us in building the next generation of AI-powered support tools
        </Typography>

        <Grid container spacing={4}>
          {jobListings.map(job => (
            <Grid item xs={12} key={job.id}>
              <Card
                sx={{
                  background: job.featured
                    ? 'linear-gradient(135deg, rgba(77, 123, 252, 0.15) 0%, rgba(31, 41, 55, 0.8) 100%)'
                    : 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                {job.featured && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 16,
                      right: -30,
                      transform: 'rotate(45deg)',
                      backgroundColor: 'secondary.main',
                      color: 'black',
                      py: 0.5,
                      px: 4,
                      fontWeight: 'bold',
                      fontSize: '0.75rem',
                      letterSpacing: 1,
                      zIndex: 1,
                      boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                    }}
                  >
                    FEATURED
                  </Box>
                )}

                <CardContent sx={{ p: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Typography variant="h4" component="h3">
                          {job.emoji} {job.title}
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                        <Chip
                          label={job.department}
                          size="small"
                          sx={{
                            bgcolor: 'rgba(77, 123, 252, 0.2)',
                            color: 'primary.main',
                          }}
                        />
                        <Chip
                          label={job.location}
                          size="small"
                          sx={{
                            bgcolor: 'rgba(255, 255, 255, 0.05)',
                          }}
                        />
                        <Chip
                          label={job.type}
                          size="small"
                          sx={{
                            bgcolor: job.featured ? 'rgba(183, 250, 55, 0.2)' : 'rgba(255, 255, 255, 0.05)',
                            color: job.featured ? 'secondary.main' : 'inherit',
                          }}
                        />
                      </Box>

                      <Typography variant="body1" paragraph color="text.secondary" sx={{ mb: 3 }}>
                        {job.description}
                      </Typography>

                      <Typography variant="subtitle1" fontWeight={600} sx={{ mb: 1 }}>
                        Responsibilities:
                      </Typography>
                      <List dense>
                        {job.responsibilities.slice(0, 3).map((item, idx) => (
                          <ListItem key={idx} sx={{ px: 0, py: 0.5 }}>
                            <ListItemIcon sx={{ minWidth: 28 }}>
                              <CheckCircleIcon color="primary" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              primary={item}
                              primaryTypographyProps={{
                                variant: 'body2',
                                color: 'text.secondary',
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          p: 3,
                          borderRadius: 4,
                          backgroundColor: 'rgba(31, 41, 55, 0.5)',
                          border: '1px solid rgba(255, 255, 255, 0.05)',
                          mb: 3,
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                          Requirements:
                        </Typography>
                        <List dense>
                          {job.requirements.slice(0, 3).map((req, idx) => (
                            <ListItem key={idx} sx={{ px: 0, py: 0.5 }}>
                              <ListItemText
                                primary={req}
                                primaryTypographyProps={{
                                  variant: 'body2',
                                  color: 'text.secondary',
                                }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>

                      <Button
                        variant="contained"
                        color={job.featured ? 'secondary' : 'primary'}
                        size="large"
                        fullWidth
                        endIcon={<ArrowForwardIcon />}
                        href={`mailto:careers@ticketsidekick.com?subject=Application for ${job.title} Position`}
                        sx={{ py: 1.5 }}
                      >
                        Apply Now
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Company Culture */}
      <Box
        sx={{
          py: 10,
          position: 'relative',
        }}
      >
        {/* Background gradient */}
      </Box>
    </Box>
  )
}

export default CareersPage
