import React from 'react'
import useDocumentHead from '../hooks/useDocumentHead'
import { Container, Typography, Box, Divider, Avatar, Grid, Card, CardContent, List, ListItem, ListItemText, Button, IconButton, Link as MuiLink } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/X'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import JustinImage from '../assets/images/justin.jpg'

const BlogPost = () => {
  const getCurrentUrl = () => window.location.href || 'https://www.ticketsidekick.com' // Fallback URL

  useDocumentHead({
    title: 'Announcing TicketSidekick: The AI-Powered Triage Engineer',
    description: 'Learn about TicketSidekick, an AI-driven platform designed to revolutionize triage engineering by automating the entire incident management lifecycle.',
  })

  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      {/* Author and Date */}
      <Box display="flex" alignItems="center" mb={3}>
        <Avatar src={JustinImage} alt="Justin Zollars" sx={{ width: 56, height: 56, mr: 2 }} />
        <Box>
          <Typography variant="subtitle1" fontWeight="bold">
            Justin Zollars, CEO
          </Typography>
          <Typography variant="body2" color="text.secondary">
            March 17, 2025
          </Typography>
        </Box>
      </Box>

      {/* Title */}
      <Typography variant="h3" component="h1" gutterBottom>
        Announcing TicketSidekick: The AI-Powered Triage Engineer
      </Typography>

      <Divider sx={{ mb: 4 }} />

      {/* Content */}
      <Box sx={{ typography: 'body1', mb: 4 }}>
        <Typography paragraph>
          Today, I'm proud to announce the launch of TicketSidekick, an AI-driven platform designed to revolutionize triage engineering by automating the entire incident management
          lifecycle.
        </Typography>

        <Typography paragraph>
          TicketSidekick emerged from our founding team's experience witnessing how valuable engineering resources were consumed by repetitive triage tasks. Our research reveals
          that triage engineers spend an alarming 32.3% of their time on manual alert correlation, ticket categorization, and routing—tasks that can be fully automated.
        </Typography>

        <Typography variant="h4" component="h2" sx={{ mt: 5, mb: 2 }}>
          Core Capabilities That Redefine Triage Engineering
        </Typography>

        <Typography variant="h5" component="h3" sx={{ mt: 4, mb: 1 }}>
          Complete Triage Automation
        </Typography>
        <Typography paragraph>
          TicketSidekick uses machine learning to automatically ingest, correlate, and filter alerts from multiple sources, eliminating false positives and reducing alert fatigue.
        </Typography>

        <Typography variant="h5" component="h3" sx={{ mt: 3, mb: 1 }}>
          Intelligent Incident Routing
        </Typography>
        <Typography paragraph>
          Our platform analyzes incident data in real-time, automatically categorizing issues and routing them to the appropriate teams without human intervention.
        </Typography>

        <Typography variant="h5" component="h3" sx={{ mt: 3, mb: 1 }}>
          AI-Powered Resolution
        </Typography>
        <Typography paragraph>
          Beyond triage, TicketSidekick can automatically resolve common issues and provide AI-generated responses to frequently asked questions, putting routine support on
          autopilot.
        </Typography>

        <Typography variant="h5" component="h3" sx={{ mt: 3, mb: 1 }}>
          Comprehensive Analytics
        </Typography>
        <Typography paragraph>Advanced dashboards quantify triage workloads and provide actionable insights to continuously optimize automated processes.</Typography>

        <Typography variant="h4" component="h4" sx={{ mt: 5, mb: 2 }}>
          Proven Results in Eliminating Triage Overhead
        </Typography>
        <Typography paragraph>Early implementations with enterprise clients have shown remarkable results:</Typography>
        <List>
          <ListItem>
            <ListItemText primary="45% reduction in average response time through automated alert correlation and filtering" />
          </ListItem>
          <ListItem>
            <ListItemText primary="40% decrease in ticket resolution time using AI-powered categorization and routing" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Scaled support operations without adding triage engineers, with some teams reducing triage headcount by 30%" />
          </ListItem>
        </List>

        <Typography variant="h5" component="h5" sx={{ mt: 5, mb: 2 }}>
          Seamless Integration for Quick Deployment
        </Typography>
        <Typography paragraph>
          TicketSidekick integrates effortlessly with existing systems including Zendesk, Freshdesk, ServiceNow, Jira, SIEM tools, and monitoring platforms. The implementation
          follows industry best practices for automated incident triage, ensuring proper data ingestion and continuous system fine-tuning.
        </Typography>

        <Typography variant="h5" component="h5" sx={{ mt: 5, mb: 2 }}>
          From Manual Triage to Full Automation
        </Typography>
        <Typography paragraph>
          Our roadmap is clear: transform triage engineering from a manual process to a fully automated function. As one client noted, "TicketSidekick has allowed us to reallocate
          our triage engineers to high-value development work by putting our support operations on autopilot."
        </Typography>

        <Typography paragraph>
          We're now accepting companies for our limited access program. Visit{' '}
          <MuiLink href="https://www.ticketsidekick.com" target="_blank">
            www.ticketsidekick.com
          </MuiLink>{' '}
          to learn how you can eliminate triage engineering overhead and put your incident response on autopilot.
        </Typography>
      </Box>

      {/* Share Buttons */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            Share this article
          </Typography>
          <Box>
            <IconButton
              color="primary"
              aria-label="share on twitter"
              onClick={() => {
                const text = 'Check out TicketSidekick: The AI-Powered Triage Engineer'
                const url = getCurrentUrl()
                window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`, '_blank')
              }}
            >
              <TwitterIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>

      {/* Forward Button */}
      <Button component={RouterLink} to="/contact" endIcon={<ArrowForwardIcon />} sx={{ mt: 2 }}>
        Get in Touch
      </Button>
    </Container>
  )
}

export default BlogPost
